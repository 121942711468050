@import "@fontsource/roboto-slab/index.css";
@import "@fontsource/roboto-slab/100.css";
@import "@fontsource/roboto-slab/200.css";
@import "@fontsource/roboto-slab/300.css";
@import "@fontsource/roboto-slab/500.css";
@import "@fontsource/roboto-slab/600.css";
@import "@fontsource/roboto-slab/700.css";
@import "@fontsource/roboto-slab/800.css";
@import "@fontsource/roboto-slab/900.css";
@import "@fontsource/montserrat";
@import "@fontsource/montserrat/100.css";
@import "@fontsource/montserrat/200.css";
@import "@fontsource/montserrat/300.css";
@import "@fontsource/montserrat/500.css";
@import "@fontsource/montserrat/600.css";
@import "@fontsource/montserrat/700.css";
@import "@fontsource/montserrat/800.css";
@import "@fontsource/montserrat/900.css";

$robot_font: "Roboto Slab";
$montserrat: "Montserrat";
$select_color: #02b1e1;
$score_green: rgb(49, 130, 47);
$score_warn: #dfae14;
$warn_color: #b23c59;

.opt_0 {
    opacity: 0;
}

.fnt_ {
    &robo {
        font-family: $robot_font;
    }

    &mont {
        font-family: $montserrat;
    }
}

@mixin wp_set($width: 0) {
    width: $width;
}

.toast-container {
    z-index: 100 !important;
}

.wd {
    &-180 {
        @include wp_set(180px);
    }

    &-200 {
        @include wp_set(200px);
    }

    &-120 {
        @include wp_set(120px);
    }

    &-130 {
        @include wp_set(130px);
    }
}