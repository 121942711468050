$select_color: #02b1e1;

div.page-footer {
  text-align: left;
  height: 50px;
  font-size: 10px;
  opacity: 0.8;
  position: fixed;
  bottom: -7mm;
  width: 100%;
}

div.page-footer p {
  margin: 0;
}

.watermark {
  display: none;
  top: 50vh;
  z-index: -9;
  width: 50vw;
  page-break-after: always;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.1;
}

table.report-container {
  page-break-after: always;
  width: 100%;
}

thead.report-header {
  display: table-header-group;
}

tfoot.report-footer {
  display: table-footer-group;
}

div.footer-info,
div.page-footer {
  display: none;
  height: 60px;
}

@media print {
  * {
    font-family: 'Roboto Slab';
  }

  .header-info {
    opacity: 0 !important;
  }

  @page {
    size: letter;
    margin: 0.25in;
    margin-bottom: 1mm;
    margin-top: 8mm !important;
  }

  .span_item {
    color: white;
    border-radius: 3px;
    padding-bottom: 2px;
    padding-top: 2px;
    text-decoration: none;
    display: inline-block;
    text-decoration: none;

    &:hover {
      color: white !important;
    }

    &.material {
      background-color: #696086 !important;
    }

    &.files {
      background-color: #b33b59;
    }

    &.doc {
      background-color: $select_color;
    }
  }

  div.page-footer,
  div.footer-info {
    display: block;
  }

  .border_shadow {
    // page-break-inside: avoid;
  }
  .done_btn {
    display: none;
  }
  .files {
    display: none !important;
  }
  .cross_line {
    width: 15%;
    height: 2px;
    background-color: $select_color;
  }
  .input-group-text {
    background-color: white !important;
  }
  .form-control {
    background-color: white !important;
  }
  .number_btn_org {
    border: 1px solid black;
    padding: 0.15rem 0.8rem;
    background-color: #fff;
    border-radius: 7px;
  }
  .section_btn {
    display: none;
  }
  .lesson_detail {
    page-break-inside: always;
    .lesson_section {
      page-break-inside: always;
    }
    .number_btn {
      border: 1px solid black;
      padding: 0.15rem 0.8rem;
      background-color: #fff;
      margin-right: 0.8rem;
      margin-bottom: 0.8rem;
      border-radius: 7px;
    }

    .detail_btn {
      padding: 0.15rem 1.5rem;
      background-color: #fff;
      border-radius: 8px;
      border: 1px solid black;
      text-decoration: none;
      color: black;

      &:hover {
        background-color: $select_color;
        color: white;
        border: 1px solid $select_color;
      }
    }

    .word_cell {
      border: 1px solid #c4c4c4;
      border-radius: 10px;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      min-width: 8rem;
      padding: 0.1rem 1.1rem;
      text-align: center;
      display: inline-block;
      margin-bottom: 0.8rem;
      $color_1: #686086;
      $color_2: #b23b59;

      &.small {
        border: 2px solid $color_1;
        font-size: 0.85rem;
      }

      &.color_1 {
        color: white;
        background-color: $color_1;
        border: 2px solid $color_1;
      }

      &.color2 {
        color: white;
        background-color: $color_2;
        border: 2px solid $color_2;
      }
    }
  }
}
