@import "/node_modules/bootstrap/scss/bootstrap";
@import "./utils.scss";

.fs-sm {
  font-size: 0.85rem;
}

.alert_border {
  border: 1px solid $select_color !important;
}

.sort_cell {
  font-size: 0.65rem;
  color: $select_color;
  border-radius: 8px;
  display: inline-block;
}

.friends_list {
  width: calc(100% - 146px);
}

.minutes_v {
  width: 200px;
}

.mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00000070;
  font-size: 4.5rem;
  z-index: 10;
  font-family: $robot_font;
  text-align: center;
  color: black;
  line-height: 100vh;
  vertical-align: middle;
}

.span_item {
  color: white;
  border-radius: 3px;
  padding-bottom: 2px;
  padding-top: 2px;
  text-decoration: none;
  display: inline-block;
  text-decoration: none;

  &:hover {
    color: white !important;
  }

  &.material {
    background-color: #696086 !important;
  }

  &.files {
    background-color: #b33b59;
  }

  &.doc {
    background-color: $select_color;
  }
}

.score {
  &_green {
    color: $score_green;
  }

  &_warn {
    color: $score_warn;
  }

  &_low {
    color: $warn_color;
  }
}

.bg_classroom {
  // background: url(../images/images/classroom_wall.png) no-repeat center 10%;
}

.border_puple {
  border: 1px solid #686086;
}

.text {
  &_info {
    color: $select_color !important;
  }

  &_warn {
    color: $warn_color !important;
  }
}

.read-text {
  font-family: $robot_font;

  &:disabled {
    background-color: transparent !important;
    border: 0;
  }
}

.bg_warn {
  background-color: $warn_color;
  border: 2px solid $warn_color;
  color: white;

  &:disabled {
    background-color: gray;
    border: 2px solid gray;
  }
}

.bg_selected {
  background-color: $select_color !important;
  border-color: $select_color !important;
  color: white;

  .back_btn {
    position: relative;
    border: 2px solid white !important;
    color: white;
    background-color: $select_color !important;
    display: inline-block;
    width: 120px;
    border-radius: 8px;
    font-weight: 600;
    left: 1.5rem;
  }
}

.uploadbtn {
  &:disabled {
    background-color: gray;
    border: 2px solid gray !important;
  }
}

.rounded_3 {
  border-radius: 10px;
}

.button_shadow {
  box-shadow: 0px 4px 8px #00000040;
}

button.border_warn {
  border: 2px solid $warn_color;

  color: $warn_color;
  &:disabled {
    opacity: .35;
  }
}

.text {
  &_not_decoration {
    text-decoration: none;
  }
}

.cursor {
  cursor: pointer;
}

.accord {
  padding-bottom: 0.5rem;
  font-family: $robot_font;

  h6 {
    font-size: 0.9rem;

    .starIcon {
      color: #b23c59;
      margin-left: 0.4rem;
      display: inline-block;
    }
  }

  .body {
    border: 1px solid #c4c4c4;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;

    ul {
      list-style: none;
      font-size: 0.9rem;
    }

    button {
      font-size: 0.8rem;
      border-radius: 5px;
      border: 1px solid #707070;
      margin-bottom: 0.4rem;
      margin-right: 0.4rem;
      background-color: white;

      &.off {
        color: #7e7e7e;
      }

      &.on {
        color: black;
      }
    }
  }
}

.border {
  &_warn {
    border: 2px solid $warn_color;
    color: $warn_color;
  }

  &_select {
    border: 2px solid $select_color;
    color: $select_color;
  }
}

.log {
  a {
    text-decoration: none;

    h2 {
      font-family: $robot_font;
      color: #000000;
    }

    .align-text-bottom {
      padding-top: 20px;
      font-family: $robot_font;
      font-size: 0.9rem;
    }
  }
}

.draft {
  position: absolute;
  right: 30px;
  top: 90px;
  z-index: 100;

  &.adapt {
    top: 130px;
    right: 10px;
  }

  &.sticky {
    position: fixed;
    right: 10px !important;
    top: 25px !important;
  }
}

.publish_btn {
  background: $warn_color 0% 0% no-repeat padding-box;
  border: 1px solid $warn_color;
  border-radius: 10px;
  color: white;
  font-family: $robot_font;
  font-size: 1.2rem;
  padding: 0.3rem 1.4rem;
  margin-bottom: 2rem;

  &.draft {
    background-color: $select_color;
    border: 1px solid $select_color;
  }

  &:disabled {
    background-color: gray;
    border: 1px solid gray;
  }
}

.fixed-top {
  position: initial !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background: transparent !important;
  $button-color: #00b1e1;

  &.box_shadow {
    box-shadow: 0px 1px 8px #0000001a;
  }

  &.main_header {
    padding-top: 0;
    padding-bottom: 0;

    .log {
      width: 285px;
      text-decoration: none;

      a {
        text-decoration: none;
      }

      .align-text-bottom {
        padding-top: 24px;
        font-family: $robot_font;
        font-size: 0.9rem;
        text-decoration: none;
      }
    }

    .m_link {
      text-decoration: none;
      color: black;
      background-color: white;
      font-family: $robot_font;
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;
      padding-left: 1.3rem;
      padding-right: 1.3rem;
      vertical-align: text-bottom;
      $left: 1.2rem;
      $top: 1.25rem;

      &.active {
        color: white;
        background-color: $select_color;
      }

      &:not(.active):hover {
        span {
          color: $select_color;
        }
      }

      span {
        display: inline-block;
        font-weight: 400;
        margin-left: 1.6rem;
      }

      &.dashboard {
        background: white url(../images/icons/home.svg) no-repeat;
        background-position: $left $top;

        &:hover {
          background: white url(../images/icons/home_hover.svg) no-repeat;
          background-position: $left $top;
        }
      }

      &.dashboard.active {
        background: $select_color url(../images/icons/home_active.svg) no-repeat;
        background-position: $left $top;
      }

      &.find {
        background: white url(../images/icons/find.svg) no-repeat;
        background-position: 1.25rem 1.4rem;

        &:hover {
          background: white url(../images/icons/find_hover.svg) no-repeat;
          background-position: 1.25rem 1.4rem;
        }

        span {
          margin-left: 1.1rem;
        }
      }

      &.find.active {
        background: $select_color url(../images/icons/find_active.svg) no-repeat;
        background-position: 1.25rem 1.4rem;

        span {
          margin-left: 1.1rem;
        }
      }

      &.adapt {
        background: white url(../images/icons/adapt.svg) no-repeat;
        background-position: 1.2rem 1.45rem;

        &:hover {
          background: white url(../images/icons/adapt_hover.svg) no-repeat;
          background-position: 1.2rem 1.45rem;
        }

        span {
          margin-left: 1.4rem;
        }
      }

      &.adapt.active {
        background: $select_color url(../images/icons/adapt_active.svg) no-repeat;
        background-position: 1.25rem 1.45rem;

        span {
          margin-left: 1.4rem;
        }
      }

      &.build {
        background: white url(../images/icons/build.svg) no-repeat;
        background-position: 1.25rem 1.45rem;

        &:hover {
          background: white url(../images/icons/build_hover.svg) no-repeat;
          background-position: 1.25rem 1.45rem;
        }

        span {
          margin-left: 1.3rem;
        }
      }

      &.build.active {
        background: $select_color url(../images/icons/build_active.svg) no-repeat;
        background-position: 1.25rem 1.45rem;

        span {
          margin-left: 1.3rem;
        }
      }

      &.community {
        background: white url(../images/icons/community.svg) no-repeat;
        background-position: 1.2rem 1.45rem;

        &:hover {
          background: white url(../images/icons/community_hover.svg) no-repeat;
          background-position: 1.2rem 1.45rem;
        }

        span {
          margin-left: 1.5rem;
        }
      }

      &.community.active {
        background: $select_color url(../images/icons/community_active.svg) no-repeat;
        background-position: 1.25rem 1.45rem;

        span {
          margin-left: 1.5rem;
        }
      }

      &.help {
        background: white url(../images/icons/help.svg) no-repeat;
        background-position: 1.2rem 1.45rem;

        &:hover {
          background: white url(../images/icons/help_hover.svg) no-repeat;
          background-position: 1.2rem 1.45rem;
        }

        span {
          margin-left: 1.4rem;
        }
      }

      &.help.active {
        background: $select_color url(../images/icons/help_active.svg) no-repeat;
        background-position: 1.25rem 1.45rem;

        span {
          margin-left: 1.4rem;
        }
      }

      img {
        vertical-align: baseline !important;
      }
    }

    .header_profile {
      vertical-align: baseline;
      background-color: transparent !important;
      border: 0;
      position: relative;

      &:focus {
        box-shadow: none !important;
      }

      &::after {
        display: none !important;
      }

      h5 {
        font-family: $robot_font;
        font-weight: 400;
        width: 70px;
        font-size: 0.9rem;
        margin-bottom: 0;
        margin-top: 12px;
      }

      img.dots {
        // vertical-align: bottom;
        bottom: 5px;
        position: absolute;
        right: 1px;
        top: 50px;
      }
    }
  }

  .buttons {
    margin-right: 8rem;

    button {
      width: 106px;
      font-family: $robot_font;
      font-weight: 400;
      border-radius: 0.5rem;
      margin-left: 1rem;
      font-size: 1.1rem;

      &.signup {
        border: 1px solid $button-color;
        background-color: $button-color;
        color: white;
      }

      &.login {
        border: 1px solid #000000;
        background-color: white;
        color: $button-color;
      }
    }
  }
}

main {
  input[type="checkbox"] {
    -webkit-appearance: none;
    background-color: #ffffff;
    border: 1px solid #686086;
    padding: 9px;
    border-radius: 5px;
    height: 20px;
    display: inline-block;
    position: relative;
    margin-right: 4px;
  }

  button {
    &.plus_btn {
      font-size: 1.1rem;
      font-family: $robot_font;
      border: 1px solid #000000;
      background-color: white;
      font-weight: 400;
      border-radius: 0.5rem;
      padding-left: 0.7rem;
      padding-right: 2.2rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      color: black;
      background: white url(../images/images/group_81.png) no-repeat;
      background-position: right center;
      background-size: 36px 36px;
    }
  }

  .act_shadow {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    &.only {
      border-radius: 10px;
    }
  }

  .form-switch {
    .form-check-input {
      width: 3rem !important;
      height: 1.5rem !important;
      margin-right: 0.7rem;
      background-color: white;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2302B1E1'/%3e%3c/svg%3e");
    }

    :checked {
      background-color: #02b1e1 !important;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    }

    .form-check-label {
      padding-top: 0.1rem;
      font-size: 1.1rem !important;
      font-weight: 500 !important;
    }
  }

  .input_box {
    b {
      display: inline-block;
      width: 2rem;
      line-height: 38px;
      vertical-align: middle;
    }

    textarea {
      width: 80%;
      border: 1px solid #707070;
      border-radius: 10px;
      padding-bottom: 0.4rem;
      padding-top: 0.4rem;
    }

    img {
      margin-left: 1rem;
    }
  }

  input[type="checkbox"]:checked {
    background-color: #4bdede;
    border: 1px solid #686086;

    color: #99a1a7;
  }

  .form-check {
    margin-bottom: 18px;

    .form-check-label {
      font-family: $robot_font;
      font-size: 0.95rem;
      font-weight: 300;
      margin-left: 4px;
    }
  }

  .dashboard_1 {
    min-height: calc(100vh - 66px);
    display: flex;
    flex-direction: column;
    background: url(../images/student-at-desk.png) no-repeat 80% 97%;

    .whiteboard {
      margin-top: 6%;
      width: 886px;
      height: 374px;
      background: url(../images/images/whiteboard.png) no-repeat;

      h3 {
        margin-top: 3rem;
        @extend .fnt_robo;
        font-size: 2.5rem;
        font-weight: bolder;
      }

      p {
        margin-top: 1rem;
        @extend .fnt_robo;
        font-weight: 300;
        font-size: 1.4rem;
      }

      button {
        @extend .fnt_robo;
        color: white;
        background-color: #b33b59;
        border: 0;

        font-weight: 500;
        padding: 0.4rem 3rem;
        font-size: 1.2rem;
        border-radius: 6px;
      }
    }

    .pink_alert {
      @extend .fnt_robo;
      background-color: #00b1e1;
      color: white;
      text-align: center;
      height: 55px;
      width: 100%;
      position: absolute;
      bottom: 0px;
    }
  }

  .build_dashboard {
    font-family: $robot_font;

    h3 {
      margin-top: 1.5rem;
      text-align: center;
    }

    h6 {
      text-align: center;
      font-size: 1.2rem;
      font-weight: 300;
    }

    .go_btn {
      background: $warn_color 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 8px #00000040;
      border-radius: 10px;
      color: white;
      border: 0;
      width: 180px;
      padding-bottom: 0.3rem;
      padding-top: 0.3rem;
      display: block;
      margin-top: 5rem;
    }

    .template_box {
      width: 445px;
      height: 623px;
      background: url(../images/images/build_templates.png) no-repeat center 15px;
      background-size: cover;
    }

    .collabration_box {
      width: 445px;
      height: 623px;
      background: url(../images/images/build_collabration.png) no-repeat center bottom;
    }

    .lesson_box {
      width: 445px;
      height: 623px;
      background: url(../images/images/build_lessson.png) no-repeat center bottom;
      background-size: cover;
    }
  }

  .how_it_board {
    h3 {
      text-align: center;
      font-family: $robot_font;
      font-weight: 400;
      font-size: 70px;
    }

    .arrow_box {
      margin-top: 2.5rem;

      .col {
        text-align: right;
      }
    }

    .comment_box {
      .col {
        .gray_box {
          background: #c4c4c4 0% 0% no-repeat padding-box;
          width: 100%;
          height: 505px;
          box-shadow: 0px 2px 8px #00000066;
          border-radius: 10px;
        }

        h3 {
          margin-top: 1.5rem;
          font-family: $robot_font;
          font-size: 48px;
          font-weight: 400;
        }

        p {
          text-align: center;
          font-size: 1.2rem;
          font-family: $robot_font;
          font-weight: 400;
        }
      }
    }

    .more_alert {
      font-family: $robot_font;
      font-size: 1.5rem;
      font-weight: 300;
      margin-top: 1.5rem;
    }
  }

  .bottom_box {
    background: transparent linear-gradient(180deg, #00b1e100 0%, #00b1e142 100%) 0% 0% no-repeat padding-box;
    height: 500px;

    .comp_moniter {
      height: 500px;
      background: url(../images/images/teacher-desk@1x.png) no-repeat center 280px;

      .desk_moniter {
        height: 328px;
        background: url(../images/images/group-56@1x.png) no-repeat center center;
        padding-top: 120px;

        button {
          background: $warn_color 0% 0% no-repeat padding-box;
          border: 3px solid $warn_color;
          color: white;
          font-weight: bold;
          font-family: $robot_font;
          padding: 0.4rem 2.3rem;
          font-size: 1.2rem;
        }
      }
    }
  }

  .vision_box {
    h3 {
      font-family: $robot_font;
      font-size: 2.8rem;
      font-weight: 400;
    }

    h4 {
      font-family: $montserrat;
      font-weight: 400;
      font-size: 1.5rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    p {
      font-family: $robot_font;
      font-weight: 200;
      font-size: 1.4rem;
    }
  }

  .student_write {
    h3 {
      font-family: $robot_font;
      font-size: 2.8rem;
      font-weight: 400;
    }

    p {
      font-family: $robot_font;
      font-weight: 200;
      font-size: 1.4rem;
    }
  }

  .meet_the {
    h3 {
      font-family: $robot_font;
      font-size: 2.8rem;
      font-weight: 400;
    }

    p {
      font-family: $robot_font;
      font-weight: 200;
      font-size: 1.4rem;
    }

    .member_box {
      text-align: center;

      .gray_circle {
        width: 250px;
        height: 250px;
        background: #c4c4c4 0% 0% no-repeat padding-box;
        border: 3px solid #000000;
      }

      h5 {
        margin-top: 1.5rem;
        font-family: $robot_font;
        font-size: 1.5rem;
        font-weight: 600;
      }

      p {
        font-family: $robot_font;
        font-weight: 200;
        font-size: 1.4rem;
      }
    }
  }

  .our_partner {
    min-height: 570px;
    background: transparent linear-gradient(180deg, #00b1e100 0%, #00b1e142 100%) 0% 0% no-repeat padding-box;

    h3 {
      font-family: $robot_font;
      font-size: 2.8rem;
      font-weight: 400;
    }

    p {
      font-family: $robot_font;
      font-weight: 200;
      font-size: 1.4rem;
    }

    .partner_row {
      padding-left: 5rem;
      padding-right: 5rem;
      padding-bottom: 4rem;

      .rounded_box {
        height: 214px;
        width: 90%;
        background: #c4c4c4 0% 0% no-repeat padding-box;
        border: 3px solid #000000;
        border-radius: 10px;
      }
    }
  }

  .contact_form {
    width: 660px;

    h3 {
      font-family: $robot_font;
      font-weight: 600;
    }

    h5 {
      font-family: $robot_font;
      font-weight: 400;
    }

    .form-label {
      font-family: $robot_font;
      font-weight: 300;
    }

    input,
    textarea {
      border: 1px solid #707070;
    }

    .submit {
      background: $warn_color 0% 0% no-repeat padding-box;
      border: 1px solid $warn_color;
      border-radius: 10px;
      color: white;
      padding: 0.4rem 1.7rem;
      font-family: $robot_font;
      font-weight: 600;

      &:disabled {
        background-color: gray;
        border: 2px solid gray;
      }
    }
  }

  .left_menu {
    width: 301px;
    font-family: $robot_font;

    min-height: calc(100vh - 63px);
    box-shadow: 2px 4px 8px #0000001a;

    h4 {
      color: white;
      text-align: center;
      background-color: $select_color;
      font-size: 1.1rem;
      font-weight: 300;
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;
    }

    .my_profile {
      margin-top: 1rem;

      img {
        display: block;
        width: 190px;
      }

      h5 {
        margin-top: 0.7rem;
        font-family: $robot_font;
        font-weight: 400;
        font-size: 1.2rem;
      }

      p {
        font-family: $robot_font;
        font-weight: 300;
        font-size: 0.9rem;
      }
    }

    .button {
      background: #00b1e1 0% 0% no-repeat padding-box;
      color: white;
      text-align: center;
      font-weight: 300;
      font-size: 1rem;
      $btn-xp: 0.7rem;
      padding-bottom: $btn-xp;
      padding-top: $btn-xp;

      img {
        margin-left: $btn-xp;
      }
    }

    .category_kind {
      label {
        display: inline-block;
        font-family: $montserrat;
        width: 40%;
      }

      b {
        padding-left: 0.5rem;
      }

      .box_decoration {
        position: relative;
        height: 40px;

        .tip_icon {
          position: relative;
          top: -45px;
          left: 88%;
        }

        .border-box {
          border: 2px solid #00b1e1;
          border-radius: 9px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          padding: 0.5rem;
          border-right: 0;

          &.gray {
            border: 2px solid #c4c4c494;
            border-right: 0;
          }
        }
      }
    }

    ul {
      list-style: none;
      padding-top: 1rem;
      margin-left: 1rem;
      font-family: $robot_font;

      .btn-box {
        display: inline-block;
        border: 1px solid #7e7e7e;
        padding: 5px 13px;
        border-radius: 6px;
        margin-right: 1.5rem;
        color: #7e7e7e;
        min-width: 110px;
        font-size: 0.8rem;

        &.selected {
          border: 2px solid #686086;
          border-radius: 6px;
          padding: 4px 12px;
          display: inline-block;
          color: #000000;
        }
      }

      .btn-toggle-box {
        border-radius: 6px;
        padding: 0.2rem 1rem;
        border: 1px solid #686086;
        background-color: #fff;
        color: $select_color;
        box-shadow: 0px 4px 8px rgb(0 0 0 / 25%);
      }

      h5 {
        font-weight: 400;
        font-size: 1.2rem;
      }

      li {
        padding-left: 1rem;
        font-size: 0.9rem;
        margin-bottom: 0.6rem;

        a {
          text-decoration: none;
          color: black;
          display: block;
          padding: 0.2rem 0.45rem;
        }
      }
    }

    .all_view {
      text-decoration: underline;
      color: $warn_color;
      margin-bottom: 1rem;
    }

    .find_title {
      margin-top: 1rem;

      h5 {
        font-weight: 400;
        font-size: 1.2rem;

        img {
          margin-left: 1rem;
          height: 25px;
        }
      }
    }
  }

  .adapt_content {
    .my_effect {
      width: 400px;
    }

    .sort_cell {
      width: 170px;
      color: $select_color;
    }
  }

  .dash_content {
    margin-top: 1.5rem;
    font-family: $robot_font;
    width: calc(100vw - 330px);

    input[type="checkbox"]:disabled {
      opacity: 1 !important;
    }

    .form-check-input[disabled]~.form-check-label,
    .form-check-input:disabled~.form-check-label {
      opacity: 1 !important;
    }

    .box_shadow {
      box-shadow: 0px 2px 8px #00000040;
      font-size: 1rem;
      border-radius: 8px;
      font-size: 1rem;
      border-radius: 8px;
      padding: 0.3rem 1.2rem;
    }

    .member_box {
      width: 100%;
      padding-top: 0.7rem !important;
      padding-bottom: 0.7rem !important;
      border: 1px solid #707070;

      .profile_box {
        width: 70px;
      }
    }

    .bg_prim {
      background-color: $select_color;
    }

    .bg_sec {
      background-color: $warn_color;
    }

    h4.caption_1 {
      font-size: 1.3rem;
      line-height: 5.5rem;
      vertical-align: bottom;
    }

    .back_btn {
      height: 44px;
      background-color: $select_color !important;
    }

    .caption {
      padding-left: 3rem;
      padding-right: 3rem;
      font-family: $robot_font;

      h3 {
        font-size: 2.5rem;
        font-weight: 700;
      }

      button {
        background: $warn_color 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 8px #00000040;
        border-radius: 10px;
        border: 0;
        color: white;
        font-size: 1.3rem;
        padding: 0.4rem 2rem;
        font-weight: 400;

        &.prm_back {
          background-color: $select_color;
          padding: 0.2rem 1.5rem;
          font-size: 1.1rem;
          height: 40px;
        }

        &.back_btn {
          background-color: white;
          color: #197d98;
          border: 2px solid #197d98;
          font-weight: 500;
          font-family: $robot_font;
          font-size: 1.2rem;
        }
      }
    }

    .template_list {
      font-family: $robot_font;
      padding-left: 3rem;
      font-weight: 300;

      h4 {
        font-weight: 400;
      }

      .card {
        font-family: $robot_font;
        border-radius: 10px;
        border: 0;
        box-shadow: 0px 4px 8px #00000040;
        width: 315px;
        $header-vpadding: 0.7rem;

        .card-header {
          color: white;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          font-weight: 500;
          vertical-align: baseline;
          padding-top: $header-vpadding;
          padding-bottom: $header-vpadding;

          .title_box {
            b {
              display: inline-block;
              vertical-align: text-bottom;
              width: 230px;
            }

            img {
              vertical-align: top;
            }
          }
        }

        .card-body {
          padding: 0;

          .controls {
            width: 72px;
            box-shadow: 0px 4px 8px #00000040;
            border-radius: 0px 10px 10px 0px;

            div {
              width: 100%;

              img {
                display: block;
                height: 23px;
              }
            }
          }

          .content {
            // width: calc(100% - 72px);
            padding: 0.8rem;
            height: 150px;
            font-size: 0.9rem;
            overflow-y: hidden;

            p {
              font-size: 0.8rem;
              margin-top: 0.6rem;
              height: 6.5rem;
              overflow-y: auto;
            }

            h6 {
              font-weight: 400;
            }
          }
        }

        &.default {
          .card-header {
            background-color: #00b1e1;
          }
        }

        &.adapt {
          .card-header {
            background-color: $warn_color;
          }
        }
      }

      .cell_box {
        padding-left: 0;
        padding-right: 0;

        .cell {
          width: 314px;
          height: 177px;
          border: 1px dashed #c4c4c4;
          border-radius: 10px;
          margin-right: 2rem;
          margin-bottom: 1.5rem;

          &.folder {
            border: 0px solid #707070 !important;
            height: auto !important;
            padding: 1rem;
          }
        }
      }
    }

    .dashboard_box {
      $width: calc(100vw - 480px);
      width: $width;
      height: calc($width * 1245 / 2985);
      border-radius: 10px;
      color: white;

      &.prompt {
        background: url(../images/images/promptsFINAL.png) no-repeat center center;
        background-size: contain;
      }

      &.communicative {
        background: url(../images/images/communicativetasksFINAL.png) no-repeat center center;
        background-size: contain;
      }

      &.game {
        background: url(../images/images/gamescolourFINAL.png) no-repeat center center;
        background-size: contain;
      }

      &.project {
        background: url(../images/images/projectFINAL.png) no-repeat center center;
        background-size: contain;
      }

      &.song {
        background: url(../images/images/songFINAL.png) no-repeat center center;
        background-size: contain;
      }

      &.presentation {
        background: url(../images/images/teacherpresentationFINAL.png) no-repeat center center;
        background-size: contain;
      }

      &.worksheet {
        background: url(../images/images/worksheetsFINAL.png) no-repeat center center;
        background-size: contain;
      }

      &.drill {
        background: url(../images/images/drillsFINAL.png) no-repeat center center;
        background-size: contain;
      }

      h3 {
        font-size: 1.7rem;
      }

      .tip_icon {
        margin-top: -20px;
      }
    }

    ul {
      list-style-type: none;
    }

    .buttons {
      margin-left: 0.5rem;

      img {
        margin-top: 0.7rem;
        margin-bottom: 0.7rem;
      }
    }
  }

  .minus_icon {
    margin-left: -0.7rem;
    margin-right: 1rem;
  }

  .section_p {
    height: 70px;
    overflow-y: hidden;
    text-align: left;
    margin-bottom: 0.5rem;
  }

  .word_cell {
    border: 2px solid #c4c4c4;
    border-radius: 10px;
    min-width: 8.5rem;
    padding: 0.1rem 1.1rem;
    text-align: center;
    font-family: $robot_font;
    display: inline-block;
    $color_1: #686086;
    $color_2: #b23b59;

    &.small {
      border: 2px solid $color_1;
      font-size: 0.75rem;
    }

    &.color_1 {
      color: white;
      background-color: $color_1;
      border: 2px solid $color_1;
    }

    &.color2 {
      color: white;
      background-color: $color_2;
      border: 2px solid $color_2;
    }
  }

  .lesson_page {
    font-family: $robot_font;

    .cross_line {
      width: 15%;
      height: 2px;
      background-color: $select_color;
    }

    h6 {
      font-weight: 500;
      font-size: 1.2rem;
    }

    .detail_btn {
      padding: 0.15rem 1.5rem;
      font-family: $robot_font;
      background-color: #fff;
      border-radius: 8px;
      border: 1px solid black;
      text-decoration: none;
      color: black;

      &:hover {
        background-color: $select_color;
        color: white;
        border: 1px solid $select_color;
      }
    }

    .shadow_effect {
      box-shadow: 0px 4px 8px #00000040;
      border-top-left-radius: 10px;

      &.only {
        border-bottom-left-radius: 10px;
      }
    }

    .number_btn {
      border: 1px solid black;
      padding: 0.15rem 0.8rem;
      background-color: #fff;
      border-radius: 7px;
    }

    .number_btn_org {
      border: 1px solid black;
      padding: 0.15rem 0.8rem;
      background-color: #fff;
      border-radius: 7px;
    }

    .description {
      color: white;
      background: transparent linear-gradient(90deg, #686086 80%, #68608600 100%) 0% 0% no-repeat padding-box;
      text-align: center;
      font-size: 1rem;
      padding-bottom: 0.3rem;
      padding-top: 0.3rem;
      font-weight: 300;
    }

    .select_activity {
      $ypadding: 0.3rem;

      .header {
        .vtitle {
          background-color: #00b1e1;
          color: white;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;

          h5 {
            font-size: 1.1rem;
          }
        }
      }

      .activity_box {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        border: 3px solid transparent;
        min-height: 220px;

        p {
          font-size: 0.8rem;
          margin-top: 1rem;
        }

        &:hover,
        &.selected {
          border: 3px solid $select_color !important;
        }
      }
    }

    .lesson_section {
      .header {
        h6 {
          color: #7e7e7e;
          font-size: 1.3rem;
          font-weight: 300;
        }
      }

      .body {
        min-height: 7rem;

        .cell_item {
          border-right: 2px solid #e7e7e7;

          &:last-child {
            border-right: 0;
          }

          background: url(../images/icons/circle_help.svg) no-repeat 95% top;
          height: 7rem;

          img {
            display: inline-block;
            height: 24px;
          }
        }

        .note_box {
          h6 {
            font-weight: 400;
            font-size: 1rem;
          }

          textarea {
            box-shadow: 1px 4px 6px #00000040;
            border-radius: 10px;
            border: 0;
          }

          .border-0 {
            border: 0;
            border-radius: 1px;
            display: inline-block;
            width: auto !important;

            &:focus {
              box-shadow: none;
              border-color: white !important;
            }
          }
        }
      }
    }
  }

  .template_wrap {
    min-height: calc(100vh - 69px);

    .header {
      margin-top: 1rem;
      position: relative;
      font-family: $robot_font;

      .back_btn {
        border: 0;
        left: 1rem;
        background: transparent;
        margin-left: 1.5rem;
        background-color: $select_color !important;
        color: white;
        padding: 0.3rem 1rem;
        font-family: $robot_font;
        border-radius: 8px;
        margin-top: 1rem;

        img {
          height: 0.9rem;
          margin-right: 0.3rem;
        }
      }

      h3 {
        font-size: 1.9rem;
      }

      p {
        font-size: 1.3rem;
        font-weight: 300;
      }

      .comment {
        font-weight: 300;
      }

      .go_btn {
        position: absolute;
        z-index: 10;
        right: 0.5rem;
        top: 40%;
        background: $warn_color 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 8px #00000040;
        border-radius: 10px;
        border: 0;
        color: white;
        padding: 0.3rem 1.5rem;

        img {
          height: 1.3rem;
        }

        &.comment_btn {
          top: 20%;
          padding: 0.7rem 0.7rem;
        }
      }
    }

    .type_cell {
      border: 1px solid #696086;
      padding: 0.5rem 1.5rem;
      border-radius: 10px;
      min-width: 15rem;
      display: inline-block;
      text-align: center;

      &.on {
        background-color: $select_color;
        color: white;
        border: 1px solid $select_color;
      }
    }

    .body {
      font-family: $robot_font;
      $ypadding: 0.6rem;

      .input-group {
        .input-group-text {
          align-items: baseline !important;
        }
      }

      .img_item {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        margin-bottom: 0.9rem;

        &.selected {
          img {
            border: 2px solid $select_color;
            border-radius: 50%;
          }
        }

        .red_circle {
          width: 68px;
          height: 68px;
          border-radius: 50%;
          background-color: $warn_color;
        }
      }

      .group {
        text-align: center;
        margin-bottom: 1.5rem;

        .caption {
          margin-top: 0.6rem;
        }

        .images {
          .number_box {
            color: white;
            background-color: $select_color;
            width: 1.7rem;
            height: 1.7rem;
            text-align: center;
            line-height: 1.7rem;
            border-radius: 50%;
            border: 1px solid white;
            position: relative;
            top: 2.5rem;
          }

          .item {
            width: 50px;

            img {
              &.selected {
                border: 3px solid $select_color;
                border-radius: 50%;
              }
            }

            .circle {
              border: 1px solid white;
              width: 68px;
              height: 68px;
              text-align: center;
              line-height: 68px;
              vertical-align: middle;
              border-radius: 50%;
              color: white;
              font-size: 2.3rem;

              &.gray {
                background-color: #c4c4c4;
              }

              &.red {
                background-color: $warn_color;
              }
            }
          }
        }
      }

      h4 {
        text-align: center;
        color: white;
        background-color: $select_color;
        padding-top: $ypadding;
        padding-bottom: $ypadding;
        margin-bottom: 0;
        font-size: 1.5rem;
      }

      .description {
        color: white;
        background: transparent linear-gradient(90deg, #686086 60%, #68608600 100%) 0% 0% no-repeat padding-box;
        text-align: center;
        padding-bottom: $ypadding;
        padding-top: $ypadding;
        font-size: 1.3rem;
      }

      .form-label {
        span.desc {
          color: #7e7e7e;
          font-weight: 200;
          font-size: 0.8rem;
        }
      }

      .input-group-text {
        border: 0;
        background-color: transparent !important;
        padding-left: 0;
        width: 115px;
      }

      select.form-select {
        border-radius: 10px;
        border-bottom-left-radius: 10px !important;
        border-top-left-radius: 10px !important;
      }

      .folder_box {
        width: 280px;
        box-shadow: 0px 4px 8px #00000040;
        border-radius: 10px;
        padding-left: 1.5rem;
        padding-bottom: 1rem;
        padding-top: 1rem;
        margin-bottom: 1.5rem;
      }

      .card {
        font-family: $robot_font;
        border-radius: 10px;
        border: 0;
        box-shadow: 0px 4px 8px #00000040;
        width: 280px;
        $header-vpadding: 0.7rem;
        margin-bottom: 1.5rem;

        .card-header {
          color: white;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          font-weight: 500;
          vertical-align: baseline;
          padding-top: $header-vpadding;
          padding-bottom: $header-vpadding;

          .title_box {
            b {
              display: inline-block;
              vertical-align: text-bottom;
            }

            img {
              vertical-align: top;
            }

            .symbol {
              width: 1.5rem;
              height: 1.3rem;
              display: inline-block;
              margin-right: 0.3rem;
            }
          }
        }

        .card-body {
          padding: 0;

          .content {
            padding: 0.8rem;

            p {
              font-size: 0.8rem;
              margin-top: 0.6rem;
              height: 6.5rem;
            }

            h6 {
              font-weight: 400;
            }

            button {
              color: white;
              border: 0;
              border-radius: 10px;
              background: $warn_color 0% 0% no-repeat padding-box;
              box-shadow: 0px 2px 8px rgb(0 0 0 / 25%);
              padding: 0.35rem 1rem;
            }
          }
        }

        &.default {
          .card-header {
            background-color: #00b1e1;

            .title_box {
              img {
                border-radius: 50%;
                border: 1px solid white;
                margin-right: 0.6rem;
              }
            }
          }
        }
      }

      button.rounded {
        border: 1px solid $warn_color;
        color: $warn_color;
        font-size: 1.2rem;
      }

      .record_box {
        margin-bottom: 2rem;
      }

      .chat_box {
        .attend_comment {
          position: relative;
          z-index: 5;
          top: -10px;
          color: $select_color;
          top: -1.6rem;
          height: 24px;
          min-width: 24px;
        }

        .message {
          position: relative;
          top: -1.6rem;
          background-color: #e5e5e5;

          &.send {
            background-color: #686086;
            color: #fff;
          }
        }

        .card {
          position: relative;
          top: -1.2rem;

          .card-body {
            padding: 0;

            .controls {
              width: 72px;
              box-shadow: 0px 4px 8px #00000040;
              border-radius: 0px 10px 10px 0px;

              div {
                width: 100%;

                img {
                  display: block;
                  height: 23px;
                }
              }
            }

            .content {
              width: calc(100% - 72px);
              padding: 0.8rem;

              p {
                font-size: 0.8rem;
                margin-top: 0.6rem;
                height: 6.5rem;
              }

              h6 {
                font-weight: 400;
              }
            }
          }
        }
      }

      .send_box {
        button {
          color: white;
          background-color: #686086;
          border: 0;
          padding: 0.3rem 1rem;
          border-radius: 6px;
          font-size: 1.3rem;
          margin-left: 1rem;
        }

        input {
          display: inline-block;
          width: calc(100% - 190px);
          padding: 0.5rem 0.6rem;
          border: 1px solid #686086;
          border-radius: 6px;
        }
      }
    }

    .control_box {
      position: absolute;
      width: 95vw;
      top: 83%;

      .circle {
        border: 0;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;

        &.blue {
          background-color: $select_color;
        }

        &.red {
          background-color: $warn_color;
        }

        .ms-2 {
          margin-left: 5px !important;
          margin-bottom: 2px;
        }
      }
    }
  }

  .profile_wrap {
    $box_width: 973px;
    $border-radius: 10px;

    .profile_box {
      width: $box_width;
      font-family: $robot_font;
      border-radius: $border-radius $border-radius 0px 0px;

      .profile_bg {
        border-radius: $border-radius $border-radius 0px 0px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 290px;
      }

      .profile_top_content {
        margin-top: -140px;

        img {
          display: block;
        }

        .camera_icon {
          position: relative;
          top: -80px;
          left: 85px;
        }

        img.logo {
          display: block;
          width: 220px;
          border: 4px solid white;
          border-radius: 50%;
        }

        button {
          display: block;
          position: relative;
          top: -115px;
          left: 800px;
          background: #fffefe 0% 0% no-repeat padding-box;
          box-shadow: 0px 2px 8px #00000033;
          border-radius: 10px;
          border: 0;
          padding: 0.3rem 1rem;
          font-size: 1.1rem;
        }
      }

      .profile_top {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 8px #00000033;
        margin-top: -145px;
        padding-top: 80px;

        h6 {
          font-size: 1.2rem;
          font-weight: 300;
        }

        button {
          display: block;
          border: 0;
          font-size: 1.2rem;
          padding: 0.7rem 1.5rem;
          margin-left: 1.3rem;
          margin-right: 1.3rem;
          margin-top: 2rem;
          background-color: transparent;

          &.selected {
            background-color: $select_color;
            color: white;
          }
        }
      }

      .profile_content {
        background: #fffefe 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 8px #00000033;
        border-radius: 0px 0px 10px 10px;
        min-height: 200px;
        margin-top: 1rem;
        padding: 1.5rem 1rem;

        h5 {
          font-size: 1.3rem;
        }

        p {
          font-size: 1.3rem;
          font-weight: 300;
        }

        button {
          background-color: $warn_color;
          color: white;
          padding: 0.5rem 1.2rem;
          border-radius: 5px;
          border: 0;
          display: block;
          height: 2.5rem;
          font-size: 1.3rem;
          box-shadow: 0px 2px 8px #00000040;

          &.cancel {
            color: $select_color;
            background-color: white;
            border: 1px solid rgb(178, 178, 178);
            padding-left: 2.5rem;
            padding-right: 2.5rem;
          }
        }
      }
    }
  }

  .adapt_wrap {
    $width: calc(100vw - 480px);

    .dashboard_box {
      width: $width;
      height: calc($width * 1245 / 2985);
      border-radius: 10px;
      color: white;

      &.prompt {
        background: url(../images/images/promptsFINAL.png) no-repeat center center;
        background-size: contain;
      }

      &.communicative {
        background: url(../images/images/communicativetasksFINAL.png) no-repeat center center;
        background-size: contain;
      }

      &.game {
        background: url(../images/images/gamescolourFINAL.png) no-repeat center center;
        background-size: contain;
      }

      &.project {
        background: url(../images/images/projectFINAL.png) no-repeat center center;
        background-size: contain;
      }

      &.song {
        background: url(../images/images/songFINAL.png) no-repeat center center;
        background-size: contain;
      }

      &.presentation {
        background: url(../images/images/teacherpresentationFINAL.png) no-repeat center center;
        background-size: contain;
      }

      &.worksheet {
        background: url(../images/images/worksheetsFINAL.png) no-repeat center center;
        background-size: contain;
      }

      &.drill {
        background: url(../images/images/drillsFINAL.png) no-repeat center center;
        background-size: contain;
      }
    }

    .left_wrap {
      width: 755px;

      ul {
        li {
          list-style: none;
          padding-top: 0.4rem;
          padding-bottom: 0.4rem;
        }
      }
    }

    .right_wrap {
      width: calc(100% - 770px);
      box-shadow: 0px 4px 8px #00000040;
      border-radius: 10px 0px 0px 10px;
    }
  }
}

.footer_link {
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;

  h6 {
    font-family: $robot_font;
    font-weight: 400;
    font-size: 1.3rem;
  }
}

.support_page {
  min-height: calc(100vh - 180px);
  background: url(../images/images/group-35@1x.png) no-repeat right bottom;
}

.contact_wrap {
  min-height: calc(100vh - 180px);
}

.fixed-bottom {
  position: initial !important;
  background-color: white !important;
  border-top: 1px solid black;

  .other_link {
    width: 100%;

    .nav-item {
      font-size: 1.5rem;
      font-family: "Roboto Slab";
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      vertical-align: middle;
      line-height: 47px;

      a {
        text-decoration: none;
        color: #000000;
      }
    }
  }
}

.border_shadow {
  box-shadow: 0px 1px 8px #00000040;
  border-radius: 10px;
}

.signup_wrap {
  .back_btn {
    border: 0;
    background: transparent;
    margin-left: 1.5rem;
    background-color: $select_color !important;
    color: white;
    padding: 0.3rem 1rem;
    font-family: $robot_font;
    border-radius: 8px;
    margin-top: 1rem;

    img {
      height: 0.9rem;
      margin-right: 0.3rem;
    }
  }

  .done_btn {
    font-family: $robot_font;
    color: white;
    border-radius: 8px;
    padding: 0.2rem 1.2rem;
    background-color: #b33b59;
    border: 0;
  }

  .add_new_box {
    color: #7e7e7e;
    font-weight: 300;
  }

  .sign_card {
    width: 760px;
    box-shadow: 0px 1px 8px #00000040;
    border-radius: 10px;

    &.vw_high {
      width: 800px;
    }

    .card-header {
      background-color: #00b1e1;
      color: white;
      text-align: center;
      font-family: $robot_font;
      font-size: 1.8rem;
      padding: 1.4rem 1rem;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .sg-row {
      input {
        border-radius: 10px;
        border: 1px solid black;
      }

      .form-label {
        font-family: $robot_font;
        font-weight: 300;
        font-size: 1.2rem;
        margin-bottom: 5px;
      }
    }

    button {
      box-shadow: 0px 1px 8px #00000040;
      font-family: $robot_font;
      font-size: 1.2rem;
      width: 10.5rem;
      margin-bottom: 0.6rem;
      color: white;
      border-radius: 8px;
      border: 0px;
      padding-bottom: 0.4rem;
      padding-top: 0.4rem;

      &.w_100 {
        width: 17rem !important;
      }

      &.w_auto {
        width: 250px;

        span {
          font-size: 1.2rem;
        }
      }

      &.sign_btn {
        background-color: #b33b59;
      }

      &.login_btn {
        background-color: #02b1e1;
      }
    }

    .login_input {
      width: 55%;

      h5 {
        color: #00b1e1;
        font-family: $robot_font;
        font-weight: 400;
      }

      input {
        border-radius: 10px;
        border: 1px solid black;
      }

      .form-label {
        font-family: $robot_font;
        font-weight: 300;
        font-size: 1.2rem;
        margin-bottom: 5px;
      }
    }

    .verify_body {
      font-family: $robot_font;

      p {
        font-size: 1rem;
        font-weight: 300;
      }

      h6 {
        font-size: 1rem;
        font-weight: 300;
        color: #00b1e1;
        padding: 0.6rem 1.5rem;
      }

      .img_block {
        height: 181px;
        display: flex;
      }

      .start_btn {
        background-color: $warn_color;
        display: block;
        padding: 0.6rem 1.5rem;
        font-weight: 400;
      }
    }
  }

  .ramp_card {
    width: 820px;
    border-radius: 10px !important;

    &.card_xl {
      width: 940px;
      border: 1px solid #7e7e7e;
    }

    .input_ex {
      .input-group-text {
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 2px solid #e5e5e5;
        border-radius: 10px 0px 0px 10px;
      }

      .form-control {
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 2px solid #e5e5e5;
        border-left: 0;
        border-radius: 0px 10px 10px 0px;
      }

      .object_cell {
        border: 2px solid #e5e5e5;
        border-radius: 0px 10px 10px 0px;
        padding: 0.35rem 0.75rem;
        height: 3.5rem;
        border-left: 0;
        font-family: $robot_font;
      }
    }

    .title {
      height: 105px;
      padding-left: 48px;

      h4 {
        font-size: 2rem;
        font-weight: 400;
      }

      p {
        font-size: 1rem;
        font-weight: 300;
      }
    }

    .h_title {
      h3 {
        font-size: 1.7rem;

        img {
          vertical-align: top;
        }
      }
    }

    .form-label {
      font-family: $robot_font;
      margin-bottom: 5px;
      font-size: 1.2rem;

      span {
        display: block;
        font-size: 0.85rem;
        font-weight: 200;
        color: #7e7e7e;
      }
    }

    .only_bottom {
      border: 0;
      border-bottom: 2px solid $select_color;
      border-radius: 0;
    }

    .form-check {
      margin-bottom: 18px;

      .form-check-label {
        font-family: $robot_font;
        font-size: 0.95rem;
        font-weight: 300;
        margin-left: 4px;
      }
    }

    input[type="number"]:disabled {
      background-color: transparent !important;
    }

    input[type="checkbox"] {
      -webkit-appearance: none;
      background-color: #ffffff;
      border: 1px solid #686086;
      padding: 9px;
      border-radius: 5px;
      height: 20px;
      display: inline-block;
      position: relative;
      margin-right: 4px;
    }

    input[type="checkbox"]:checked {
      background-color: #4bdede;
      border: 1px solid #686086;
      color: #99a1a7;
    }

    input,
    select {
      border: 1px solid black;
      border-radius: 10px;
    }

    .name_box {
      height: 105px;
      display: inline-block;
      vertical-align: bottom;
      font-size: 0.9rem;
      font-weight: 400;
      width: 5rem;
    }

    .vtitle {
      color: white;
      font-family: $robot_font;
      background-color: $select_color;
      text-align: center;
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;
      border-radius: 10px;
      font-weight: 300;
    }

    .grade {
      font-family: $robot_font;

      .no-border {
        border: 0;
        background-color: white;
        font-size: 1.5rem;
        margin-bottom: 1.3rem;

        &:not(.active):hover {
          font-weight: 700;
        }

        &.active {
          color: $select_color;
        }
      }

      .rounded-border {
        border: 2px solid #00b1e1;
        border-radius: 10px;
        min-height: 4.5rem;
        font-size: 1.5rem;
        position: relative;
        padding: 17px 9px;
        background-color: #fffefe;

        img {
          position: absolute;
          top: 5px;
          right: 5px;
          z-index: 50;
        }

        // background: #fffefe url(../images/icons/circle_help.svg) no-repeat 98% 3px;

        &:not(.active):hover {
          border: 3px solid #00b1e1;
          padding: 16px 8px;
        }

        &.active {
          color: white;
          background: $select_color;
          // background: $select_color url(../images/icons/circle_help.svg) no-repeat 98% 3px;
        }
      }

      .rounded-bottom-border {
        border: 2px solid #00b1e1;
        border-radius: 10px;
        min-height: 4rem;
        width: 8.5rem;
        text-align: left;
        margin-bottom: 1.4rem;
        vertical-align: top;
        background-color: #fffefe;
        position: relative;
        // background: #fffefe url(../images/icons/circle_help.svg) no-repeat 99% 99%;

        &:not(.active):hover {
          border: 3px solid #00b1e1;
        }

        img {
          position: absolute;
          bottom: 5px;
          right: 5px;
          z-index: 50;
        }

        &.active {
          color: white;
          background-color: $select_color;
        }
      }
    }

    .card-header {
      background: transparent !important;
      border-bottom: 0;
      font-family: $robot_font;
    }

    .card-footer {
      margin-top: 1.5rem;
      background-color: #fff;
      border-top: 0;
      padding: 0;
      border-radius: 10px;

      .arrow_box {
        width: 126px;
        height: 62px;
        background-color: $select_color;
        border-bottom-left-radius: 10px;

        &.empty {
          background-color: transparent;
        }

        &.right {
          text-align: right;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 10px;

          h3 {
            color: white;
            cursor: pointer;
            font-family: $robot_font;
            background-color: $select_color;
            width: 126px;
            text-align: center;
            vertical-align: middle;
            line-height: 60px;
            font-size: 1.8rem;
            font-weight: 400;
            height: 62px;
            border-bottom-right-radius: 10px;
          }
        }
      }
    }

    .build_btn {
      font-family: $robot_font;
      background-color: #fff;
      border: 0;
      font-size: 1.3rem;
      border-radius: 8px;
      padding: 0.25rem 1.6rem;

      &.on {
        background-color: $select_color;
        color: white;
      }
    }
  }

  .action_box {
    width: 6.6rem;
    position: fixed;
    @media only screen and (max-width: 992px) {
      position: unset;
    }
    &.sticky {
      top: 35px !important;
    }

    &.right_box {
      left: calc(50% + 520px);
    }

    button {
      width: 6rem;
      display: inline-block;
      font-family: $robot_font;

      border-radius: 10px;
      margin-bottom: 0.8rem;

      &.draft_btn {
        background-color: #fff;
        border: 1px solid $warn_color;
        color: $warn_color;
      }

      &.draft_btn:disabled {
        color: gray !important;
      }

      &.finish_btn {
        color: white;
        background-color: $warn_color;
        border: 1px solid $warn_color;
      }

      &.finish_btn:disabled {
        background-color: gray !important;
        border: 1px solid gray !important;
      }
    }
  }
}

.photo_box {
  .main_photo {
    border: 1px solid black;
    border-radius: 50%;
    z-index: 0;
  }

  .icon {
    z-index: 10;
    position: relative;
    top: 40px;
    right: 30px;
  }
}

.slider_box {
  .slider_indicator {
    width: 15px;
    height: 15px;
    display: block;
    border-radius: 50%;
    margin-left: 4px;
    margin-right: 4px;
    background: #c4c4c4 0% 0% no-repeat padding-box;

    &.active {
      background: #00b1e1 0% 0% no-repeat padding-box;
    }
  }
}

.dashboard_video {
  &.w-100 {
    .modal-dialog {
      max-width: 88%;
    }
  }

  .modal-dialog {
    max-width: 600px;
  }

  .closeBtn {
    position: absolute;
    top: 5px;
    right: -75px;
  }

  .modal-content {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 8px #00000040;
    border-radius: 10px;
    border: 0;

    .modal-header {
      background: #00b1e1 0% 0% no-repeat padding-box;
      border-radius: 10px 10px 0px 0px;
      color: white;
      @extend .fnt_robo;
      font-weight: 300;
      font-size: 1.7rem;
      justify-content: center;

      .modal-title {
        font-weight: 400;
        font-size: 1.7rem;
        text-align: center;
      }
    }
  }

  .youtube {
    background-color: #c4c4c4;
    width: 509px;
    height: 285px;
    border-radius: 10px;

    img {
      display: block;
    }
  }
}

.my_effect {
  $radio: 1rem;
  @extend .fnt_robo;
  border-radius: $radio;
  @extend .bg-body;
  box-shadow: 1px 1px 8px #00000015;

  &.man_item {
    border: 1px solid #c4c4c4;
    display: flex;

    .man_icon {
      margin-right: 0.5rem;
      margin-left: 0.5rem;
      vertical-align: bottom;
      display: inline-block;
    }

    h5,
    p {
      margin-bottom: 0;
      vertical-align: bottom;
    }

    p {
      color: #c4c4c4;
    }
  }

  .input-group-text {
    @extend .bg-body;
    border-radius: $radio;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    font-weight: 300;

    &.end_append {
      border-top-right-radius: $radio;

      border-bottom-right-radius: $radio;
    }
  }

  input {
    @extend .border-start-0;
    border-radius: $radio;

    &::placeholder {
      color: #c4c4c4;
    }

    &.with_icon {
      border-right: 0;
    }
  }
}

.item_control_overlay {
  font-family: $robot_font;
  font-weight: 300;
  position: absolute;
  background-color: white;
  padding: 0.5rem 1rem;
  color: black;
  border-radius: 6px;
  box-shadow: 0px 1px 8px #00000040;

  .content {
    h5 {
      font-weight: 400;
    }
  }
}

.help_btn {
  font-family: $robot_font;
  border: 2px solid #00b1e1;
  border-radius: 10px;
  width: 281px;
  display: block;
  height: 189px;

  background: url(../images/icons/circle_help.svg) no-repeat 98% 3px;

  &.no_icon {
    background-image: none;
    height: 80px;
  }

  h5 {
    text-align: center;
    width: 100%;
    margin-top: 1.4rem;
    font-weight: 400;
    font-size: 1.3rem;
  }

  p {
    font-weight: 300;
  }

  &:hover {
    border: 4px solid #00b1e1;
  }
}

.custom-file {
  display: flex;
  align-items: center;
  position: relative;
  flex: 1 1 auto;
  height: calc(1.5em + 0.75rem + 2px);

  input {
    position: relative;
    z-index: 2;
    width: 85%;
    height: calc(1.5em + 0.75rem + 2px);
    margin: 0;
    overflow: hidden;

    opacity: 0;
  }

  .custom-file-label {
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    overflow: hidden;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.5rem;

    &.browser::after {}

    // &::after {
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   bottom: 0;
    //   z-index: 3;
    //   display: block;
    //   height: calc(1.5em + 0.8rem);
    //   padding: 0.375rem 0.75rem;
    //   line-height: 1.5;
    //   width: 28px;
    //   color: #495057;
    //   content: '';
    //   background: url(../images/icons/upload_circle.svg) no-repeat 0px 5px;
    //   border: 0;
    // }
  }
}

.input-group {
  .desc {
    color: #7e7e7e;
    font-weight: 200;
    font-size: 0.8rem;
  }
}

.round-border {
  border-radius: 10px;
}

.instructions {
  .add_wrap {
    width: calc(50% + 3rem + 24px);
    padding-top: 0.7rem;
  }
}

.helps {
  font-family: $robot_font;

  .accordion-item {
    border: 0;

    .accordion-header {
      button {
        color: $select_color;
        box-shadow: 0px 2px 8px #00000040;
        border-radius: 10px;
        border: 1px solid black;
      }
    }
  }

  .accordion-collapse {
    border: 1px solid black;
    border-radius: 10px;
    box-shadow: 0px 2px 8px #00000040;
  }

  .accordion-item:last-of-type {
    .accordion-button.collapsed {
      border-radius: 10px !important;
    }

    .accordion-collapse {
      border-radius: 10px !important;
    }
  }

  .accordion-button::after {
    background: url(../images/icons/drop_icon.svg) no-repeat center center;
  }

  .accordion-body {
    h5 {
      color: #b9556e;
    }

    .youtube {
      margin-top: 1rem;
      margin-bottom: 1rem;
      width: 336px;
      background-color: #c4c4c4;
      border-radius: 10px;
      height: 157px;
    }

    p {
      font-weight: 300;
      line-height: 1.7;
    }
  }

  .comment {
    a {
      color: #c06178;
    }
  }
}

.activity_menu {
  .dropdown-toggle {
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: transparent !important;
    border: 0 !important;
    box-shadow: none !important;

    &::after {
      border: 0px !important;
    }
  }
}

.class_label {
  width: 96px;
  display: inline-block;
}

.bold {
  .form-check-label {
    font-weight: 400 !important;
    font-size: 1rem !important;
    font-family: $robot_font;
  }
}

.org_members {
  position: relative;
  font-family: $robot_font;
  font-size: 1.1rem;
  border-left: 1px solid $select_color;
  border-bottom: 1px solid $select_color;
  border-top: 1px solid $select_color;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  margin-left: 1rem;

  .bookmarked {
    position: absolute;
    right: 0.3rem;
    top: -3px;
  }
}

.shadow_effect {
  box-shadow: 0px 4px 8px #00000040;

  &.only {
    border-bottom-left-radius: 10px;
  }
}

.draft_wrapper {
  min-height: 260px;

  &.sm {
    min-height: 230px;
  }

  a {
    text-decoration: none;
    color: black;
  }
}

.demo-editor {
  height: 230px !important;

  &.sm {
    height: 150px !important;
    overflow-y: auto !important;

    .DraftEditor-root {
      height: 130px;
    }
  }

  margin-top: -5px;
  border-left: 1px solid #f1f1f1;
  border-right: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;

  .DraftEditor-root {
    height: 210px;
  }
}

.public-DraftStyleDefault-block {
  margin: 0.25em 0 !important;
  font-family: $robot_font;
}

.modal_save_btn {
  color: white;
  background-color: #b23c59;
  border: 1px solid #b23c59;
}

.save_draft_btn {
  border-radius: 10px;
  border: 1px solid #b23c59;
  background: #fffefe;
  color: $warn_color;
  font-weight: 600;
  font-family: $robot_font;
}

.save_finish_btn {
  border-radius: 10px;
  border: 1px solid #b23c59;
  background: #b23c59;
  font-family: $robot_font;
  font-weight: 600;
  color: white;

  &:disabled {
    opacity: 0.5;
  }
}

.top_border_10 {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}

.bottom_border_10 {
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border: 1px solid #fff;
  min-height: 200px;
  background: #fffefe;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.input_wrapper {
  height: 85dvh;
  overflow-y: auto;
  overflow-x: hidden;
}

.top_blue_action_bar {
  background-color: #00b1e1;
  color: white;

  .back_btn {
    position: absolute;
    left: 15%;
    background-color: transparent !important;
    border: 1px solid white;
    color: white;
    border-radius: 8px;
    font-family: $robot_font;
    padding: 4px 12px;
  }

  .back_btn_o {
    background-color: transparent !important;
    border: 1px solid white;
    font-family: $robot_font;
    color: white;
    border-radius: 8px;
    padding: 4px 12px;
  }
}

.edit_box {
  width: 800px;

  label {
    font-weight: normal;

    span {
      color: #7e7e7e;
      font-family: Roboto Slab;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: -0.18px;
      text-transform: lowercase;
    }
  }

  .save_btn {
    color: white;
    font-family: Roboto Slab;
    font-weight: 700;
    border-radius: 10px;
    border: 1px solid #b23c59;
    background: #b23c59;
    padding: 0.25rem 0.75rem;

    &:disabled {
      background-color: gray;
      border: 1px solid gray;
    }
  }
}

.top_tools {
  button {
    border-radius: 5px;
    border: 1px solid #000;
    background: #fffefe;
    font-family: $robot_font;
  }
}

.go_info {
  background: #fffefe url(../images/icons/top_right_arrow.svg) no-repeat calc(100% - 3px) 3px;
  font-family: $robot_font;
  border-radius: 5px;
  border: 1px solid #000;
}

.back_button {
  border-radius: 10px;
  background: #00b1e1;
  border: 0px;
  color: white;
  font-weight: 500;
  font-family: $robot_font;
}

.card-header {
  &.title_box {
    border-radius: 10px 10px 0px 0px;
    background: #00b1e1 !important;
  }
}

.unit_wrapper {
  min-height: 200px;
  height: 200px;
  overflow-y: auto;
}

.lesson_wrapper {
  min-height: 200px;
  height: 200px;
  overflow-y: auto;
}

.selectedItem {
  color: white !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background: #00b1e1;
}

.unit_box {
  border-radius: 10px !important;
  border: 2px solid #fff;
  background: #fffefe;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  .card-header {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
  }

  .card-body {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    padding-bottom: 10px;
  }
}

.rdw-editor-toolbar {
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
  border-top: 1px solid rgb(206, 212, 218) !important;
  border-left: 1px solid rgb(206, 212, 218) !important;
  border-right: 1px solid rgb(206, 212, 218) !important;
}

.rdw-editor-main {
  border-bottom-right-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  padding: 0.2rem;
  border-bottom: 1px solid rgb(206, 212, 218) !important;
  border-left: 1px solid rgb(206, 212, 218) !important;
  border-right: 1px solid rgb(206, 212, 218) !important;
}

.instruct_cmd {
  color: #00b1e1;
}

.section_btn {
  border-radius: 5px;
  border: 2px solid #00b1e1;
  background: #00b1e1;
  padding: 0.2rem 0.8rem;
}

.edit_lesson {
  .modal-dialog {
    max-width: 930px;
  }
}

.title_box {
  background-color: #00b1e1;
  color: white;
}

.draft_title {
  background-color: $warn_color;
  color: white;
}

.adapt_title {
  background-color: #686086;
  color: white;
}

.top_round {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.red_bg {
  background-color: $warn_color;
  color: white;
}

.text_blue {
  color: #00b1e1 !important;
}

.my_select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url(../images/icons/red-down-arrow.svg) !important;
  background-repeat: no-repeat !important;
  background-position-x: 100%;
  background-position-y: 5px;
  border-radius: 10px !important;
}

.rounded-10x {
  border-radius: 10px !important;
}

.input-group {
  .form-control:disabled {
    background-color: #e9ecef !important;
  }
}

.go_link {
  background: #00b1e1 url(../images/icons/top_right_arrow.svg) no-repeat calc(100% - 3px) 3px !important;
  color: white;
}

.text_warn {
  color: $warn_color;
}

.empty_controls {
  width: 27px !important;
}

.controls_bar {
  background-color: #00b1e1;
}

.remove_btn {
  color: #b23c59;
  font-family: Roboto Slab;
  font-weight: 700;
  border-radius: 10px;
  border: 1px solid #b23c59;
  background: white;
  padding: 0.25rem 0.75rem;
}

.template_1 {
  width: 805px;
  height: 337px;
  background: url(../images/images/imageA.png) no-repeat;
}

.template_2 {
  width: 805px;
  height: 337px;
  background: url(../images/activity_header_default.png) no-repeat;
}

.template_3 {
  width: 805px;
  height: 337px;
  background: url(../images/images/imageC.png) no-repeat;
}

.template_4 {
  width: 805px;
  height: 337px;
  background: url(../images/images/imageD.png) no-repeat;
}

.template_5 {
  width: 805px;
  height: 337px;
  background: url(../images/images/imageE.png) no-repeat;
}

.template_6 {
  width: 805px;
  height: 337px;
  background: url(../images/images/imageF.png) no-repeat;
}

.template_7 {
  width: 805px;
  height: 337px;
  background: url(../images/images/imageG.png) no-repeat;
}

.template_8 {
  width: 805px;
  height: 337px;
  background: url(../images/images/imageH.png) no-repeat;
}

.view_wrapper {
  width: 805px;
}

.header_title {
  border-radius: 10px 10px 0px 0px;
  background: #00b1e1;
  color: #fffefe;
  font-family: Roboto Slab;
  font-size: 18px;
  font-style: normal;
  line-height: 21px;
  /* 116.667% */
  letter-spacing: -0.27px;
}

.lh-md {
  line-height: 1.8;
}

.custom_file {
  input[type="file"]::-webkit-file-upload-button {
    display: none;
  }

  input[type="file"]::file-selector-button {
    display: none;
  }
}

.se-wrapper-wysiwyg {
  font-family: $robot_font !important;
  font-size: 1rem !important;
}

.order-cell {
  width: 20px;
}

.list-inc {
  list-style: disc inside none !important;

  li::marker {
    display: block !important;
    // font-size:12px !important;
    content: "• ";
  }
}

.linkBtn {
  background-color: #00b1e1;
  color: white;
  // width: 200px;
}

.adapt_label {
  font-size: 14px;
  color: #b23c59;
}

.ck-body-wrapper {
  z-index: 10000;
}

.input-warning {
  border: 1px solid $warn_color;
}

.vocab_cell {
  border: 2px solid #c4c4c4 !important;
}

.color_legend {
  width: 18px;
  height: 18px;
  border-radius: 4px;
  display: block;

  &.material {
    background-color: #696086 !important;
  }

  &.files {
    background-color: #b33b59;
  }

  &.doc {
    background-color: $select_color;
  }
}